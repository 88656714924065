import { Toaster } from "react-hot-toast";
import { Fragment, StrictMode } from "react";
import { DesignTokensProvider, getDesignTokens } from "@gradience/ui";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "./lib/config";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import React from "react";
import posthog from "posthog-js";
import { routeTree } from "./routeTree.gen";
import mockDate from "mockdate";

const { VITE_MOCK_DATE: MOCK_DATE } = config;

if (MOCK_DATE) {
  const parsedDate = new Date(MOCK_DATE);
  if (!isNaN(parsedDate.getTime())) {
    mockDate.set(parsedDate);
    console.log(`Date is mocked to: ${parsedDate.toISOString()}`);
  } else {
    console.warn(
      `Invalid REACT_APP_MOCK_DATE format: ${MOCK_DATE}. Skipping date mock.`
    );
  }
} else {
  console.log("REACT_APP_MOCK_DATE not set. Using the actual system date.");
}

posthog.init("phc_EC9v20nXmmAc58MrqVhS5h0X0MkKvWd0QhnpkYvIEXz", {
  api_host: "https://us.posthog.com",
});

Bugsnag.start({
  apiKey: "04b7c4a92fd1d307ae9b2d52b1bebae3",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "04b7c4a92fd1d307ae9b2d52b1bebae3" });

const ErrorBoundary =
  Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? Fragment;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient({
  logger: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
});
const stripePromise = config.VITE_OFFLINE_DEVELOPMENT_MODE
  ? null
  : loadStripe(config.VITE_STRIPE_PUBLISHABLE_KEY);

const rootElement = document.getElementById("root") as HTMLElement;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  const inputStyles = {
    backgroundColor: "transparent",
    padding: "12px 16px",
    borderRadius: "12px",
    border: "1px solid rgba(20, 20, 20, 0.40)",
    boxShadow: "none",
    lineHeight: "115%",
  } as const;
  const designTokens = getDesignTokens(config.VITE_TENANT_THEME_COLOR);

  root.render(
    <ErrorBoundary>
      <StrictMode>
        <Elements
          stripe={stripePromise}
          options={{
            mode: "setup",
            currency: "usd",
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap",
              },
            ],
            appearance: {
              labels: "floating",
              rules: {
                ".Label": {
                  opacity: 0.6,
                } as {},
                ".Input": inputStyles,
                ".Input:focus": {
                  ...inputStyles,
                  border: `1px solid ${designTokens.colors.misc.Brand}`,
                },
              },
              variables: {
                colorPrimary: designTokens.colors.surface.Brand,
                colorBackground: designTokens.colors.surface.Background,
                colorText: designTokens.colors.text.default,
                colorTextPlaceholder: designTokens.colors.text.subdued,
                colorDanger: designTokens.colors.text.brand,
                spacingUnit: "4px",
                borderRadius: "10px",
                fontFamily: designTokens.typography.body.fontFamily,
                fontSmooth: "always",
              },
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            <DesignTokensProvider themeColor={config.VITE_TENANT_THEME_COLOR}>
              <Toaster />
              <RouterProvider router={router} />
            </DesignTokensProvider>
          </QueryClientProvider>
        </Elements>
      </StrictMode>
    </ErrorBoundary>
  );
}
